import { useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

function SearchInput({ onQueryChange, onSubmit, placeholderText }) {
  const [searchQuery, setSearchQuery] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    setSearchQuery(newQuery);
    onQueryChange(newQuery);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="relative rounded-full bg-white border border-gray-300 hover:border-gray-500 focus-within:ring-2 focus-within:ring-blue-500">
        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="w-5 h-5 text-gray-400" />
        </span>
        <input
          className={`block w-full rounded-full pl-10 pr-3 py-2 text-sm ${
            placeholderText ? "placeholder-gray-700" : "placeholder-gray-500"
          } focus:outline-none ${
            placeholderText
              ? "focus:placeholder-gray-700"
              : "focus:placeholder-gray-400"
          }`}
          type="text"
          placeholder={placeholderText ? placeholderText : "binary search tree lecture"}
          value={searchQuery}
          onChange={handleInputChange}
        />
      </div>
    </form>
  );
}

export default SearchInput;
